.account-profile-name {
    font-size: 28px;
    font-weight: bold;
    margin: 12px 12px 10px 0;
}

.account-profile-list-name {
    font-size: 20px;
    font-weight: bold;
    margin: 6px 6px 5px 0;
}

.account-profile-name-list-subtitle {
    font-size: 12px;
    margin: 6px 6px 5px 0;
    vertical-align: text-bottom;
}

.account-profile-name-subtitle {
    font-size: 12px;
    margin: 12px 12px 10px 0;
    vertical-align: text-bottom;
}

.account-profile-image {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%; /* 圓形圖片 */
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    padding: 2px;
    background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4);
}

.account-profile-image-FirstName {
    display: inline-flex;
    vertical-align: middle; /* 與旁邊的文字垂直對齊 */
    margin-right: 10px;
    font-size: 80px;
    color: white;
    background-color: #666; /* 替代顏色 */
    width: 150px;
    height: 150px;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    border-radius: 50%;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    text-align: center;
}

.account-profile-list-image {
    width: 20px;
    height: 20px;
    border-radius: 50%; /* 圓形圖片 */
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    padding: 2px;
    background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4);
}

.account-profile-image-list-FirstName {
    display: inline-flex;
    vertical-align: middle; /* 與旁邊的文字垂直對齊 */
    font-size: 20px;
    color: white;
    background-color: #666; /* 替代顏色 */
    width: 40px;
    height: 40px;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    border-radius: 50%;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    text-align: center;
}

.profileAdditioinInfoIcon{
    font-size: 30px;
}

.account-link-list{
    text-decoration: none; /* 移除底線 */
    color: black;
}

.account-list-div {
    margin-bottom: 20px;
    background-color: #f9fafb;
    padding: 10px 10px 10px 20px;
    border-radius: 12px;
}
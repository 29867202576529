.editor-container {
    border: 1px solid #ddd;
    padding: 10px;
    max-height: 300px;
    min-height: 100px; /* 確保容器有高度 */
    background: #fff;
    margin-right: 100px;
    margin-left: 100px;
}

.hljs {
  background-color: #000; /* 設置背景為黑色 */
  color: #fff; /* 設置文字顏色為白色 */
  padding: 10px; /* 增加內邊距 */
  border-radius: 5px; /* 添加圓角 */
  overflow-x: auto; /* 避免長代碼溢出 */
  font-family: "Courier New", Courier, monospace; /* 設置代碼字體 */
}
/* Modal 背景遮罩 */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal 內容 */
.modal-heart-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

/* 愛心樣式 */
.hearts-modal {
  display: flex;
  justify-content: center;
  gap: 10px; /* 愛心間距 */
  margin: 20px 0;
}

.heart-modal {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.heart-modal.red {
  color: #ff0000; /* 紅色亮起 */
}

.heart-modal.black {
  color: #ddd; /* 灰色 */
}

/* 關閉按鈕 */
.close-modal {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.post-idea-small-desc{
  display: -webkit-box;
  font-size: 14px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  text-align: center;
}

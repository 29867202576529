.homeTitle{
    color:black;
    font-size: 48px;
    font-weight: bold;
}

.homeSubTitle{
    color:black;
    margin-top: 10px;
    font-size: 20px;
}

.homeActionBtn{
    margin-top: 150px;
}

.hero-section {
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hero-section h1 {
    font-size: 3rem;
    font-weight: bold;
}

.hero-section p {
    font-size: 1.25rem;
    margin: 20px 0;
}

.btn-cta {
    background-color: black;
    color: white;
    padding: 12px 24px;
    border-radius: 50px;
    font-weight: bolder;
    margin-right: 100px;
    transition: background-color 0.3s ease, color 0.3s ease; /* 加入過渡效果 */
}

.btn-cta:hover {
    background-color: #333; /* 懸停時改變背景顏色 */
    color: #fff; /* 懸停時改變文字顏色 */
  }
  
  /* #FBF600 F7EC70 #FFF65E*/
.btn-cta-secondary {
    background-color: #FFF65E;
    color: black;
    padding: 12px 24px;
    border-radius: 50px;
    font-weight: bolder;
    transition: background-color 0.3s ease, color 0.3s ease; /* 加入過渡效果 */
}

.btn-cta-secondary:hover {
    background-color: #FEF58B; /* 懸停時改變背景顏色 */
    color: #333; /* 懸停時改變文字顏色 */
}
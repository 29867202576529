@media (max-width: 600px) {
    .homeTitle{
        color:black;
        font-size: 40px;
        font-weight: bold;
    }

    .homeSubTitle{
        color:black;
        font-size: 20px;
        margin-top: 5px;
    }


    .btn-cta {
        background-color: black;
        color: white;
        padding: 12px 24px;
        border-radius: 50px;
        font-weight: bolder;
        margin-right: 50px;
    }
    
    .btn-cta-secondary {
        background-color: #FEF58B;
        color: black;
        padding: 12px 24px;
        border-radius: 50px;
        font-weight: bolder;
    }

    .parent-nav-icon {
        display: none;
    }

    .parent-nav-icon-rwd{
        width: 30px; /* 設定寬度 */
        height: 30px; /* 設定高度 */
        background-color: #f9fafb; /* 背景色 */
        border-radius: 50%; /* 設置為圓形 */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: box-shadow 0.2s ease; /* 添加過渡效果 */
    }

    /* 正常狀態的陰影（可選） */
.parent-nav-icon-rwd:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 滑鼠懸停時的陰影 */
  }
  
  /* 按下狀態的陰影 */
  .parent-nav-icon-rwd:active {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* 按下時的更深陰影 */
  }
}

/* 模態框的樣式 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 背景半透明 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


.graybtn{
  font-weight: bold;
  background-color: #f9fafb;
  color: black;
  padding: 8px 20px;
  transition: background-color 0.3s ease, color 0.3s ease; /* 加入過渡效果 */
}

.graybtn:hover{
  background-color: white;
  color: black;
  padding: 8px 20px;
}

.graybtn:active{
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 8px 20px;
}

.icon-lightbulb{
  margin-right: 5px;
  font-weight: bold;
}

.graybtn .icon-login{
  margin-right: 10px;
}

.graybtn .icon-login{
  margin-right: 10px;
}

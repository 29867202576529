.btnSendCreatePost{
    background-color: #1cb3a4;
    font-weight: bold;
    color: white;
}

.right-info h6 {
    font-weight: bold;
}

.right-info p {
    font-size: 0.9rem;
}

.badge-custom {
    background-color: #00bfa5;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

.textarea-custom {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #f8f9fa;
    resize: none;
}

.input-custom{
    width: 100%;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid rgb(204, 204, 204);
}

.input-custom.focused {
    border: 2px solid rgba(13, 110, 235);
    outline: none;
}

.input-custom::-webkit-input-placeholder,.textarea-custom::-webkit-input-placeholder {
    color: rgba(4, 12, 16, 0.45);
    font-size: 14px;
}
.header {
    background-color: white;
}

.headerlogo {
    color: black;
}

.headerlink {
    color: black; 
    font-weight: bolder;
}

.footer {
    padding-top: 100px;padding-bottom: 50px;
}

/* --------- */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f9fafb;
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
}

/* FBF600 F7EC70 FFF65E*/
.launchIdeabtn{
    font-weight: bolder;
    background-color: #FFF65E;
    color: black;
    padding: 8px 20px;
    transition: background-color 0.3s ease, color 0.3s ease; /* 加入過渡效果 */
}

.launchIdeabtn:hover{
    background-color: #FEF58B;
    color: black;
    padding: 8px 20px;
}

.loginbtn{
    font-weight: bold;
    background-color: #f9f9f9;
    color: black;
    padding: 8px 20px;
    transition: background-color 0.3s ease, color 0.3s ease; /* 加入過渡效果 */
}

.loginbtn:hover{
    background-color: #1cb3a4;
    color: white;
    padding: 8px 20px;
}

.loginbtn:active{
    background-color: #1cb3a4;
    color: white;
    border: 1px solid black;
    padding: 8px 20px;
}

.loginbtn .icon-lightbulb{
    font-weight: bold;
}

.loginbtn .icon-login{
    margin-right: 10px;
}

.content-wrapper {
    margin-top: 50px;
}

.content-main {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.content-main-Login {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    width: 600px;
    margin: 0 auto;
}

.boldtext{
    font-weight: bolder;
}


/* -----Padding----- */
.padding5px{
    padding:5px;
}

/* -----Margin----- */
.marginRight10px{
    margin-right: 10px;
}

.marginLeft10px{
    margin-left: 20px;
}

.parent-nav-icon-rwd{
    display: none;
}

.remove-link-css{
    text-decoration: none; /* 移除底線 */
    color: black;
}